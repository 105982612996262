import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';
import HipayPayment from '@/services/payments/HipayPayment';
import SofortForm from './Sofort/SofortForm.vue';

export default {
  mixins: [cartMixin, addressMixin],
  computed: {
    hipayPayment() {
      return new HipayPayment({
        cart: this.cart,
        auth: this.$auth,
        http: this.$http,
        addressService: this.addressService,
      });
    },
  },
  methods: {
    toggleLoader(val) {
      this.$store.commit('shared/wind/setIsLoading', val);
    },
    async startPayment({ paymentData, paymentMethod }) {
      try {
        // const res = await paymentHelper.createOrderBancCard(
        //   this.paymentData,
        //   paymentMethodSelected
        // );
        this.toggleLoader(true);

        const res = await this.hipayPayment.createOrder({
          paymentData,
          paymentMethod,
        });
        const idcart = this.cart.IdCart;

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.response, 'text/xml');
        const response = this.$xml2json(xmlDoc).response;

        let typeMessage = 'warning';
        if (response.state === 'completed') {
          typeMessage = 'success';

          this.$router.replace(
            this.localePath(
              `/cart/thanks/accepted?orderid=${res.order.id}&cartid=${idcart}&reference=800215064118&amount=${res.order.amount}&currency=${res.order.currency}`
            )
          );
        }
        if (response.state === 'forwarding') {
          typeMessage = 'success';
          window.open(response.forward_url, '_self');
        }
        this.$message({
          type: typeMessage,
          message: response.state + ' - ' + response.message,
        });
      } catch (err) {
        console.error(err);
        if (err.response) {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(err.response.data, 'text/xml');
          const response = this.$xml2json(xmlDoc).response;
          // if (err.response.status === 400) {
          this.$message.error(this.$t(response.message));
          // }
        } else {
          this.$message.error('Something is wrong');
        }
      } finally {
        this.toggleLoader(false);
      }
    },
  },
  components: {
    SofortForm,
  },
};
